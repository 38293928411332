.swiper-vertical > .home-page-swiper-p-container {
  right: 16px;
  left: auto;
  transform: initial;
  top: 100px;
  width: 40px;
}

@media screen and (min-width: 48em) {
  .swiper-vertical > .home-page-swiper-p-container {
    right: 20px;
    top: 20px;
  }
}

.home-page-swiper-p-item {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  cursor: pointer;
  border-radius: 50%;
  color: #8c7e70;
  background: #000;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #8c7e70;
  margin-bottom: 8px;
  position: relative;
  transition: opacity ease-in-out 0.25s;
}

.home-page-swiper-p-item:hover {
  opacity: 0.67;
}

.home-page-swiper-p-item:last-child {
  margin: 0;
}

.home-page-swiper-p-item--completed {
  background: #8c7e70;
  border-color: #8c7e70;
  color: white;
}

.home-page-swiper-p-item--future {
  background: #000;
  border-color: #8c7e70;
  color: white;
}

.home-page-swiper-p-item--today {
  background: #a78256;
  border-color: #a78256;
  color: white;
}

.home-page-swiper-p-item--current::after {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #8c7e70;
  top: calc(50% - 4px);
  left: -14px;
  background: transparent;
  position: absolute;
}
