.pagination-coaches > .swiper-pagination-bullet {
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 100%;
  border: 2px solid #8c7e70;
  padding: 2px;
  background: transparent;
}

.pagination-coaches img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 100%;
}

.swiper-vertical > .pagination-coaches {
  right: 16px;
}

@media screen and (min-width: 30em) {
  .swiper-vertical > .pagination-coaches {
    right: 40px;
  }
}

@media screen and (min-width: 48em) {
  .swiper-vertical > .pagination-coaches {
    right: 42px;
  }
}
